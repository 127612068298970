import React from 'react'
import ReactDataGrid from 'react-data-grid'
import {
    Paper,
    Typography,
    Button
} from '@material-ui/core'
import 'react-data-grid-multiline-header/style.css'
import Helper from 'react-data-grid-multiline-header'

import Api from './api'

import { ProgressBar } from 'react-bootstrap'

const columns = [
    { key: 'title', name: 'Title', editable: true, resizable: true },
    { key: 'count', name: 'Count', editable: true, resizable: true } ]

const rows = [
    {title: 'row1', count: 20}, 
    {title: 'row1', count: 40}, 
    {title: 'row1', count: 60}
]

const FloatNumFormatter = ({ value }) => {
    let truncated = value

    if (truncated === null) {
        return "N/A"
    }
    
    if (Number.isInteger(truncated)) {
        return truncated
    }

    // send toFixed(2), unless there is only one digit
    if (Number.isInteger(truncated * 10)) {
        return Number(truncated).toFixed(1)
    } 

    return Number(truncated).toFixed(2)
}

const ProbaFormatter = ({ value }) => <ProgressBar now={value} label={`${value}%`} />

class App extends React.Component {
    constructor() {
        super()

        this.state = { columns, rows, helper: new Helper(columns) }
        this.onGridRowsUpdates = this.onGridRowsUpdated.bind(this)
    }

    async componentDidMount() {
        let rows = await Api.getData('rows')
        let columns = await Api.getData('columns')
        let probas = await Api.getData('probas')

        columns = columns.map(c => ({ 
            key: c, name: c, 
            editable: true, 
            formatter: FloatNumFormatter
        }))
        columns.unshift({ key: "whitespae", name: "", width: 5 })
        columns.unshift({ 
            key: "proba", name: "P(Amyloidosis)", 
            frozen: true, formatter: ProbaFormatter
        })
        columns.unshift({ key: "patient", name: "Patient id", frozen: true })
        console.log(columns)

        rows = rows.map((r, i) => ({ 
            proba: (probas[i] * 100).toFixed(1), 
            patient: `Patient ${i+1}`, ...r 
        }))

        console.log(rows)

        const helper = new Helper(columns)

        this.setState(state => {
            return { rows, columns, helper }
        })
    }

    onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
        console.log(this)
        let rows = this.state.rows.slice()

        console.log(rows)

        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated }
        }

        const probas = await Api.getData('probas', rows)
        console.log(probas)
        rows = rows.map((r, i) => ({ 
            ...r,
            proba: (probas[i] * 100).toFixed(1), 
        }))

        this.setState(state => {
            return { rows }
        })
    }

    render() {
        const { rows, columns, helper } = this.state

        return (
                <Paper>
                    <div className='react-grid-multiline-header'>
                        <ReactDataGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            onGridRowsUpdated={this.onGridRowsUpdated}
                            onColumnResize={(idx, width) => ({})}
                            enableCellSelect={true}
                            headerRowHeight={helper.headerRowHeight}
                            helper={helper.columns}
                            minHeight={700}
                        />
                    </div>
                </Paper>
               );
    }
}

export default App
