// AJAX calls
//

const url = 'http://amyloidosis.plumdeq.xyz/api'

const getData = async (type, data) => {
    let res = null

    if (typeof data !== 'undefined') {
        res = await fetch(`${url}/${type}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ rows: data })
        })
    }
    else {
        res = await fetch(`${url}/${type}`)
    }

    const json = await res.json()

    if (typeof(json) === "string") {
        return JSON.parse(json)
    } else {
        return json
    }
}


const Api = {
    getData,
}

export default Api
